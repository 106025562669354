import React from 'react'


export default function FAQBlock({faqs}) {
    return (
        <div className="bg-gray-900">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="lg:max-w-2xl lg:mx-auto lg:text-center">
                <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">Frequently asked questions</h2>
                <p className="mt-4 text-gray-400">
                If you have a question that's not answered here, reach out to us at <a href='mailto:hello@conexresearch.com'>hello@conexresearch.com</a>
                </p>
            </div>
            <div className="mt-20">
                <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                {faqs.map((faq) => (
                    <div key={faq.id}>
                    <dt className="font-semibold text-white">{faq.question}</dt>
                    <dd className="mt-3 text-gray-400">{faq.answer}</dd>
                    </div>
                ))}
                </dl>
            </div>
            </div>
        </div>
    )
}
